import React, { useState, useEffect } from 'react'
import HeaderImage from '../headerImage/HeaderImage';
import Header from "../header/Header"
import Footer from '../footer/Footer';
import ValueItem from '../form/ValueItem';
import useAuthState from './useAuthState';
import { useCookies } from 'react-cookie';

function LoginApp(props:PropsValue) {
  const [cookies] = useCookies(['redirect_url']);

  const {token, auth_item, authentificate, updateAuthItem} = useAuthState()
  const [msessage, setMessage] = useState(null)


  const mainContent = {
    margin: "80px auto 0 auto",
    width:"100%",
    maxWidth:"1040px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center"
  }

  const btnBox = {
    margin:"30px auto 0 auto",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    cursor:"pointer"
  }

  function changeHandler(name,value){
    updateAuthItem(name,value)
  }

  function login(){
    authentificate(auth_item)
  }

  
  useEffect(() => {
    
    if(token && token.error){
      setMessage("IDまたはパスワードが一致しません。")

      return false
    } 
    if(token){
      const getRedirect = async () => {
        if(cookies["redirect_url"]){
          window.location.href  = cookies["redirect_url"]
        }else{
          window.location.href  = "/"
        }
      };
      getRedirect();
    }
    
  },[token,cookies]);


  return(

    <div>
      <HeaderImage />
      <Header />
      <main>
        <div style={mainContent}> 
          
          <div>
            <ValueItem name="username" placeholder="" label="メールアドレス"  className="login-item"  changeHandler={changeHandler} />
          </div>
          <div >
            <ValueItem name="password" type="password" placeholder="" label="パスワード" className="login-item"  changeHandler={changeHandler}  />
          </div>
          <div className='message'>{msessage}</div>
          <div style={btnBox} onClick={login}><button className="big-btn">ログイン</button></div>
        </div>
        
      </main>
      
      <Footer />
    </div>

  )
}

export default LoginApp
