import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import TopApp from "./top/TopApp"
import ParkListApp from './park/ParkListApp.js';
import ParkDetailApp from './park/ParkDetailApp.js';
import BookConfirmApp from './book/BookConfirmApp.js';
import BookSuccessApp from './book/BookSuccessApp.js';
import LoginApp from './login/LoginApp.js';
import CreateUserApp from './createUser/CreateUserApp.js';
import CreatedPreUserApp from './createUser/CreatedPreUserApp.js';
import CreateProfileApp from './createUser/CreateProfileApp.js';
import ConfirmProfileApp from './createUser/ConfirmProfileApp.js';
import CreateUserMailSendApp from "./createUser/CreateUserMailSendApp"
import CreateUseSuccessApp from "./createUser/CreateUseSuccessApp"
import ProfileApp from './mypage/ProfileApp.js';

import CommingBookApp from './mypage/ComingBookApp.js';
import HistoryBookApp from './mypage/HistoryBookApp.js';
import BookDetailApp from './mypage/BookDetailApp.js';

import RegulationApp from './pages/RegulationApp.js';
import CompanyApp from './pages/CompanyApp.js';


config.set(configuration);

class App extends React.Component{

render(){
return(
    <div>
      <Routes>
        <Route  path='/' element={<TopApp />} />
        <Route  path='/regulation' element={<RegulationApp />} />
        <Route  path='/company' element={<CompanyApp />} />

        <Route  path='/login' element={<LoginApp />} />
        <Route  path='/user/create' element={<CreateUserApp />} />
        <Route  path='/user/create/sent' element={<CreateUserMailSendApp />} />
        <Route  path='/user/create/profile/:id' element={<CreateProfileApp />} />
        <Route  path='/preuser/:id' element={<CreatedPreUserApp />} />
        
        <Route  path='/user/confirm/:id' element={<ConfirmProfileApp />} />
        <Route  path='/user/create/success' element={<CreateUseSuccessApp />} />

        <Route  path='/park' element={<ParkListApp />} />
        <Route  path='/park/:id' element={<ParkDetailApp />} />
        <Route  path='/book/success' element={<BookSuccessApp />} />
        <Route  path='/book/:id' element={<BookConfirmApp />} />

        <Route  path='/mypage/book/comming' element={<CommingBookApp />} />
        <Route  path='/mypage/book/:id' element={<BookDetailApp />} />
        <Route  path='/mypage/book/history' element={<HistoryBookApp />} />
        <Route  path='/mypage/profile' element={<ProfileApp />} />
       

      </Routes>
    </div>
)}}

const container = document.getElementById('root')
const root = createRoot(container)

root.render((<BrowserRouter ><App /></BrowserRouter>))

