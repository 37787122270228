import React, {  useEffect, useState } from 'react'
import logo from "../image/logo.svg"
import config from 'react-global-configuration';
import useAuthState from '../login/useAuthState';

function Header(props:PropsValue) {

  const {token, getToken, logout}  = useAuthState()
  const [isToken, setIsToken]  = useState(false)

  const header_wrap = {
    width:"100%",
    position:"fixed",
    top:"0",
    left:"0",
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: config.get("color_light_green"),
    color:"#fff",
    zIndex:"10"
  }

   const headerLogo = {
      width:"82px"
   }
   const headerMenu = {
    margin:"0 auto",
    display: 'flex',
    justifyContent: 'center',
    flexDirection:"row"
  }
  const headerMenuLi = {
    fontSize:".8em",
    margin:"0 36px",
    fontWeight:"bold",
    cursor:"pointer"
  }
  const headerMenuRLi = {
    fontSize:".8em",
    margin:"0 6px",
    fontWeight:"bold",
    cursor:"pointer"
  }
  const headerMenuRight = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection:"row",
    alignItems:"center",
    cursor:"pointer"
  }
  const headerMenuRightLogin = {
    fontSize:".8em",
    margin:"0 36px",
    fontWeight:"bold",
    cursor:"pointer"
  }

  const headerMenuRightRegist = {
    backgroundColor: config.get("color_black"),
    fontSize:".8em",
    color:"#fff",
    padding:"7px 20px 5px 20px",
    display: 'flex',
    alignItems:"center",
    borderRadius:"20px"
  }
  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
  
    setIsToken(!token || token.error ? false : true)
    
  },[token]);

  function moveTo(path){
    closeMenu()
    window.location.href = path
  }

  function login(){
    
    //setCookie('redirect_url', window.location.href ,{ path: '/' })
    moveTo("/login")
  }

  function logoutHandler(){
    logout()
    moveTo("/login")
  }

  function displayMenu(){
    const element = document.querySelector("menu");
    if(!element) return false
    
    element.style.display = "flex"
  } 

  function closeMenu(){
   
    const element = document.querySelector("menu");
    if(!element) return false
    
    element.style.display = "none"
  }

  return(
    <div>
       <div id="header_triger" class="hamburger-menu" onClick={displayMenu}>
          <input type="checkbox" id="menu-btn-check" />
          <label for="menu-btn-check" class="menu-btn"><span></span></label>
      </div>


      <menu>
      <div class="close" onClick={closeMenu}><img src="https://jwt50.com/wp/wp-content/themes/twentytwentyone/assets/images/close.svg" alt="close" /></div>
      <div id="menu"  >    
        <ul id="menu-innter">
          <li  onClick={() => moveTo("/")} >トップ</li>
          <li  onClick={() => moveTo("/")} >目土隊とは</li>
          <li  onClick={() => moveTo("/#usage")}>ご利用方法</li>
          <li  onClick={() => moveTo("/#notice")}>注意事項</li>
          <li  onClick={() => moveTo("/")}>よくある質問</li>
        </ul>
      </div>
    </menu> 


    
    <div style={header_wrap}>
      <header className={"header"}>
        <div><a href="/"><img style={headerLogo} src={logo} alt="目土隊"/></a></div>
        <ul style={headerMenu}>
          <li style={headerMenuLi}  onClick={() => moveTo("/")} >目土隊とは</li>
          <li style={headerMenuLi} onClick={() => moveTo("/#usage")}>ご利用方法</li>
          <li style={headerMenuLi} onClick={() => moveTo("/#notice")}>注意事項</li>
          <li style={headerMenuLi} onClick={() => moveTo("/")}>よくある質問</li>
        </ul>
        <div style={headerMenuRight}>
          { isToken ? null :<div style={headerMenuRightLogin} onClick={() => login()}>ログイン</div> }
          { isToken ? null :<div style={headerMenuRightRegist} onClick={() => moveTo("/user/create")}>新規登録</div> }
          { isToken ?  <div style={headerMenuRLi}  onClick={logoutHandler}>ログアウト</div>  : null}
          { isToken ?  <div style={headerMenuRightLogin}  onClick={() => moveTo("/mypage/book/comming")}>マイページ</div>  : null}
        </div>
      </header>
  </div>
  </div>
  )
}

export default Header
